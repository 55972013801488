@import '../../../defaults';

.anw-conversion-banner {
  background: url('./images/banner-search-list.webp') no-repeat 100% 0;
  background-size: cover;
  margin-top: map-get($spacers, 3) * -1;

  @include media-breakpoint-up(md) {
    background-position: -20px -16px;
    margin-top: map-get($spacers, 5) * -1;
  }

  .anw-banner-content {
    z-index: 2;
  }

  .anw-gradient-right {
    background: rgba(255, 255, 255, 0);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    content: '';
    width: 80px;
  }

  .anw-gradient-left {
    background: rgba(255, 255, 255, 0);
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    bottom: 0;
    content: '';
    left: 0;
    top: 0;
    width: 200px;
    z-index: 1;
  }

  .anw-bg-transparent {
    background: none;
  }
}
